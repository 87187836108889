import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, Pressable } from 'react-native';
import AppButton from '../components/AppButton';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebase from '../firebase/firebaseConfig'
import SigninWithGoogle from './SigninWithGoogle';
import SigninWithPhone from './SigninWithPhone';
import ScreenSize from "../components/ScreenSize";
import { ScrollView } from 'react-native';
import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { SafeAreaView } from 'react-native';
import { TextInput } from 'react-native';
import filter from 'lodash.filter'
import * as Animatable from 'react-native-animatable'
import { ActivityIndicator } from 'react-native';
import * as Linking from 'expo-linking';
import * as Sharing from 'expo-sharing';
// import Animated, { FadeIn, FadeOut, SlideInDown, SlideOutDown } from 'react-native-reanimated';
// import { SafeAreaProvider } from "react-native-safe-area-context";
import SigninWithFacebook from './SigninWithFacebook';
import { Input, Switch, VStack, FormControl, Popover, Modal, Button } from "native-base";


export default function MyAccount(props) {
      const styles = useStyles();
      const screenSize = ScreenSize();
      const routeprofileID = props.route.params?.profileID

      const [searchQuery, setsearchQuery] = useState('');
      const [fullMeals, setfullMeals] = useState([]);
      const [loading, setloading] = useState(false)
      const [isOpen, setOpen] = useState(false);
      const rubberBandAnimeRef = useRef()
      const [animation, setAnimation] = useState('slideOutUp');
      const [animationPressable, setAnimationPressable] = useState('fadeIn');
      const [following, setFollowing] = useState(false)
      const [authenticatedUser, setAuthenticatedUser] = useState(null)
      const [routeUser, setRouteUser] = useState(null)
      const [ads, setAds] = useState([])
      const [phoneNumber, setPhoneNumber] = useState("");
      const [modalVisible, setModalVisible] = useState(false)


      useEffect(() => {
            onAuthStateChanged(getAuth(), (data) => {
                  setloading(true)
                  if (data) {
                        firebase
                              .firestore()
                              .collection("Users")
                              .doc(data.uid)
                              .onSnapshot((snapshot) => {
                                    setAuthenticatedUser(snapshot.data());
                              })

                        if (routeprofileID != undefined)
                              firebase
                                    .firestore()
                                    .collection("Users")
                                    .doc(routeprofileID)
                                    .get()
                                    .then((snapshot) => {
                                          setRouteUser(snapshot.data());
                                    });

                        firebase
                              .firestore()
                              .collection('Users')
                              .doc(routeprofileID === undefined ? data.uid : routeprofileID)
                              .collection('Ads')
                              .orderBy("creation", "desc")
                              .onSnapshot((snapshot) => {
                                    setAds(snapshot.docs.map((doc) => doc.data()))
                                    setfullMeals(snapshot.docs.map((doc) => doc.data()))
                              })

                        firebase.firestore()
                              .collection("following")
                              .doc(firebase.auth().currentUser.uid)
                              .collection("userFollowing")
                              .onSnapshot((snapshot) => {
                                    let following = snapshot.docs.map(doc => {
                                          const id = doc.id;
                                          return id
                                    })

                                    if (following.indexOf(routeprofileID) > -1) {
                                          setFollowing(true);
                                    } else {
                                          setFollowing(false);
                                    }
                              })
                  } else {
                        setAuthenticatedUser(null)
                  }
                  setloading(false)
            })
      }, [props.route.params?.profileID])


      const handleSearch = (query) => {
            setsearchQuery(query)
            const formattedQuery = query.toLowerCase()
            const filteredData = filter(fullMeals, (user) => {
                  return contains(user, formattedQuery)
            })
            setAds(filteredData)
      }

      const contains = ({ ShortDescription, LongDescription, adOwner }, query) => {
            const { name } = adOwner
            if (ShortDescription?.toLowerCase().includes(query) || LongDescription?.toLowerCase().includes(query) || name?.toLowerCase().includes(query)) {
                  return true
            }
            return false
      }

      const toggleSheet = () => {
            setOpen(!isOpen);
      }

      const handleShare = async () => {
            // const redirectUrl = Linking.createURL('path/into/app', {
            const redirectUrl = Linking.createURL('MyAccount', {
                  queryParams: { profileID: routeprofileID == undefined ? authenticatedUser?.uid : routeprofileID },
                  // https://laptop-galaxy.web.app/ProductInfo?productId=d9Eun1MbODUc8NLUaIK0
            });
            // console.log(redirectUrl)

            Sharing.shareAsync(redirectUrl, { dialogTitle: 'Share Profile Of Lap-Market' })

            if (Sharing.isAvailableAsync() === true) {
                  // console.log('Sharing.isAvailableAsync() === true')
            }
            if (Sharing.isAvailableAsync() === false) {
                  // console.log('Sharing.isAvailableAsync() === false')
            }
      }

      const newProduct = {
            ...routeUser,
            followTime: firebase.firestore.FieldValue.serverTimestamp()
      }

      const onFollow = () => {
            firebase.firestore()
                  .collection("following")
                  .doc(firebase.auth().currentUser.uid)
                  .collection("userFollowing")
                  .doc(routeprofileID)
                  .set(newProduct)

            // props.sendNotification(user.notificationToken, "New Follower", `${props.currentUser.name} Started following you`, { type: 'profile', user: firebase.auth().currentUser.uid })
      }

      const onUnfollow = () => {
            firebase.firestore()
                  .collection("following")
                  .doc(firebase.auth().currentUser.uid)
                  .collection("userFollowing")
                  .doc(routeprofileID)
                  .delete()
      }

      const AnimatedPressable = Animatable.createAnimatableComponent(Pressable)


      if (loading) {
            <ActivityIndicator size='large' color='red'></ActivityIndicator>
      }


      if (authenticatedUser === null) {
            return (
                  <View style={styles.containerr}>

                        <Modal
                              isOpen={modalVisible}
                              onClose={() => setModalVisible(false)}
                              style={{
                                    // opacity: 0.7
                                    // width:'40%',
                                    // alignSelf:'center'
                                    // position: 'absolute',
                                    // backgroundColor: 'purple'
                                    // flex: 1,
                                    // height: '1000%',
                                    // ...StyleSheet.absoluteFillObject,
                              }}
                        // height={screenSize.height * 8}
                        // position='absolute'
                        // style={{ flex: 1, height: '100%', position: 'absolute', ...StyleSheet.absoluteFillObject, }}
                        // width={screenSize.isWide ? screenSize.width / 2 : screenSize.width / 1.2} 
                        >
                              <Modal.Content
                                    // height={9999}
                                    // maxWidth="400px"
                                    style={{
                                          backgroundColor: '#ffffff',
                                          // marginVertical: -1050,
                                          // justifyContent: 'center',
                                          // alignItems:'center',
                                          flex: 1
                                    }}
                              >
                                    <View style={{
                                          justifyContent: 'center',
                                          // alignItems: 'center',
                                          // backgroundColor: 'red'
                                    }}>
                                          <Modal.Header style={{ height: 50, justifyContent: 'center' }}>
                                                <View style={{
                                                      flexDirection: 'row',
                                                      justifyContent: 'space-between',
                                                      alignItems: 'center',
                                                      // margin: 10
                                                }}>

                                                      <Text style={{
                                                            fontSize: screenSize.ratio(60),
                                                            // fontSize: (screenSize.height / 115) + (screenSize.width / 115),
                                                            fontWeight: '800',
                                                            alignSelf: 'center'
                                                      }}>
                                                            OTP Verification
                                                      </Text>
                                                </View>

                                                <Modal.CloseButton style={{
                                                      justifyContent: 'flex-start',
                                                      margin: -4
                                                      // alignSelf: 'center',
                                                      // backgroundColor: 'green',
                                                }} />
                                          </Modal.Header>
                                    </View>
                                    <Modal.Body style={{
                                          // backgroundColor: 'yellow',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                    }}>
                                          <FormControl style={{
                                                // backgroundColor: 'blue',
                                                flex: 1
                                          }}>
                                                <Image resizeMode="stretch"
                                                      style={{
                                                            alignSelf: 'center',
                                                            width: screenSize.ratio(7),
                                                            height: screenSize.ratio(6),
                                                            // borderColor: 'red',
                                                            // borderWidth: 2
                                                            // width: (screenSize.height / 9) + (screenSize.width / 9),
                                                            // height: (screenSize.height / 7) + (screenSize.width / 10),
                                                            // marginTop: 5
                                                      }}
                                                      source={require('../assets/otp.jpg')} />
                                                {/* }} source={{ uri: 'https://thumbs.dreamstime.com/b/otp-one-time-password-step-authentication-data-protection-internet-security-concept-otp-one-time-password-step-authentication-data-254434939.jpg' }} /> */}

                                                {/* <Text style={{ fontSize: (screenSize.height / 135) + (screenSize.width / 135), fontWeight: '500', marginBottom: 3 }}>
                                                      Phone Number:
                                                </Text> */}

                                                <SigninWithPhone
                                                      navigation={props.navigation}
                                                      phoneNumber={phoneNumber} />

                                                {/* <View style={{ backgroundColor: '#e5e5e5', borderRadius: 12, margin: 10, justifyContent: 'center', paddingHorizontal: 15, paddingTop: 7.5, paddingBottom: 13 }}>
                                                      <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '900', color: '#3b3b3b' }}>Phone Number :</Text>
                                                      <VStack style={{ marginTop: 7 }}>
                                                            <Input
                                                                  type="text"
                                                                  value={phoneNumber}
                                                                  onChange={(e) => setPhoneNumber(e.target.value)}
                                                                  placeholder="+201012973660"
                                                            />
                                                      </VStack>
                                                </View> */}


                                                {/* <Button.Group space={2}>
                                                      <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                                                            setModalVisible(false);
                                                      }}>
                                                            Cancel
                                                      </Button>
                                                </Button.Group> */}

                                                {/* <input
                                                      type="text"
                                                      value={phoneNumber}
                                                      onChange={(e) => setPhoneNumber(e.target.value)}
                                                      placeholder="+201012973660"
                                                /> */}


                                                {/* <Text style={{
                                                      fontSize: (screenSize.height / 60) + (screenSize.width / 165),
                                                      // fontSize: (screenSize.height / 65) + (screenSize.width / 130),
                                                      // fontSize: (screenSize.height + screenSize.width) / 70
                                                      marginTop: 50,
                                                      fontWeight: '600', alignSelf: 'center'
                                                }}>{modalDescription}
                                                </Text> */}

                                          </FormControl>
                                    </Modal.Body>
                                    <Modal.Footer style={{
                                          // height: screenSize.ratio(12),
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          // flex: 1
                                          // backgroundColor: 'blue'
                                    }}>
                                          <Text style={{
                                                fontSize: screenSize.ratio(70),
                                                marginVertical: -10,
                                                marginHorizontal: -7,
                                                // lineHeight: 22,
                                                color: 'grey',
                                                // backgroundColor: 'green',
                                                // opacity: 0.7,
                                                // flex: 1,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                alignSelf: 'center',
                                                // backgroundColor:'red'
                                          }}>⚠ To ensure the safety and reliability of our services, carriers or entire regions with consistently low SMS success rates may become temporarily unavailable. Including{' '}

                                                <Image style={{
                                                      height: 15,
                                                      width: 60,
                                                      resizeMode: 'cover',
                                                      marginRight: 5,
                                                      borderRadius: 3,
                                                      alignSelf: 'center',
                                                      top: 4
                                                      // backgroundColor: 'red',
                                                      // aspectRatio: 2.959537572254335,
                                                      // marginTop: 8 
                                                }} source={require('../assets/we2.jpg')} />
                                                {' '}&{'  '}
                                                <Image style={{
                                                      height: 15,
                                                      width: 60,
                                                      resizeMode: 'cover',
                                                      alignSelf: 'center',
                                                      top: 4
                                                      // backgroundColor: 'red',
                                                      // aspectRatio: 4 / 1,
                                                      // marginTop: 8
                                                      // borderRadius: 10,
                                                }} source={require('../assets/etisalat.png')} />
                                          </Text>
                                    </Modal.Footer>
                              </Modal.Content>
                        </Modal>


                        <View style={styles.logoContainer}>
                              <Image source={{ uri: 'https://img.freepik.com/premium-vector/laptop-with-coffee-vector-icon-illustration_138676-358.jpg?w=2000' }} style={styles.logo} />
                              <Text style={styles.subtitle}>Welcome To The Galaxy</Text>
                              <Text style={styles.subtitle2}>Of Laptops!</Text>
                        </View>

                        <View style={styles.buttonContainer}>
                              <AppButton
                                    title="Login"
                                    color='blue'
                                    onPress={() => props.navigation.navigate('Login')} />
                              <AppButton
                                    title="Register"
                                    color='red'
                                    onPress={() => props.navigation.navigate('Register')} />
                        </View>

                        <Text style={{ justifyContent: 'center', alignItems: 'center', color: 'grey', fontSize: 14, fontWeight: 'bold' }}>Or Use Another Method</Text>


                        {/* <div style={{ backgroundColor: 'blueviolet' }}>
                              <h2>OTP Verification</h2>
                              <div>
                                    <label>Phone Number:</label>
                                    <input
                                          type="text"
                                          value={phoneNumber}
                                          onChange={(e) => setPhoneNumber(e.target.value)}
                                          placeholder="918144356767"
                                    />
                              </div>
                              <SigninWithPhone phoneNumber={phoneNumber} />
                        </div> */}

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '40%', margin: 20 }}>
                              <SigninWithGoogle navigation={props.navigation} />

                              {/* <SigninWithFacebook /> */}

                              {/* <TouchableOpacity>
                                    <Feather name="phone-call" size={screenSize.ratio(42)} color={"#000"} />
                              </TouchableOpacity> */}

                              <TouchableOpacity
                                    onPress={() => {
                                          setModalVisible(true)
                                    }}
                                    style={{
                                          alignItems: "center",
                                          justifyContent: "center",
                                          // backgroundColor: 'red'

                                          // marginHorizontal: screenSize.width / 50,
                                    }}
                              >
                                    <Image style={{ height: 52, width: 52 }} source={{ uri: 'https://cdn2.iconfinder.com/data/icons/phone-call-functions/154/phone-function-call-telephone-512.png' }}></Image>

                                    <Text style={{ fontSize: 10, fontWeight: "900" }}>
                                          Phone Number
                                    </Text>
                              </TouchableOpacity>
                        </View>
                  </View>
            );

      } else if (routeprofileID === undefined || routeprofileID === authenticatedUser?.uid) {
            // } else if (user?.uid === profileID || profileID === undefined) {
            const date = new Date(authenticatedUser?.creationTime && authenticatedUser?.creationTime.toDate());
            const displayDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear());
            return (
                  // <GestureHandlerRootView style={styles.container}>
                  //       <SafeAreaProvider>
                  <View style={{ flex: 1 }}>
                        {isOpen && (
                              <>
                                    <AnimatedPressable animation={animationPressable} duraton="600" style={styles.backdrop} onPress={() => {
                                          setAnimation('slideOutDown')
                                          setAnimationPressable('fadeOut')
                                          setTimeout(toggleSheet, 650)
                                    }} />


                                    <Animatable.View style={styles.sheet} animation={animation} duraton="600" easing='ease-out-back'>
                                          <TouchableOpacity onPress={handleShare}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", marginHorizontal: 5, marginVertical: 5 }}>
                                                      <Text style={{ fontSize: 25 }}>
                                                            Share Profile
                                                      </Text>
                                                      <MaterialCommunityIcons name="share-variant" size={30} color="#52575D" />
                                                </View>
                                          </TouchableOpacity>

                                          <TouchableOpacity onPress={() => {
                                                props.navigation.navigate('Favorites'
                                                      // , { userID: authenticatedUser?.uid }
                                                )
                                          }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", marginHorizontal: 5, marginVertical: 5 }}>
                                                      <Text style={{ fontSize: 25 }}>
                                                            Favorites
                                                      </Text>
                                                      <MaterialIcons name="favorite" size={30} color="#52575D" />
                                                </View>
                                          </TouchableOpacity>

                                          <TouchableOpacity onPress={() => { props.navigation.navigate('Following', { userID: authenticatedUser?.uid }) }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", marginHorizontal: 5, marginVertical: 5 }}>
                                                      <Text style={{ fontSize: 25 }}>
                                                            Following
                                                      </Text>
                                                      <MaterialIcons name="follow-the-signs" size={30} color="#52575D" />
                                                </View>
                                          </TouchableOpacity>
                                    </Animatable.View>
                              </>
                        )}


                        <View style={styles.container}>
                              {/* <ImageBackground
                              style={styles.backgroundImage}
                              source={require("../assets/bg.png")}
                              blurRadius={120}
                        > */}

                              <ScrollView style={{ height: '100%', width: '100%' }} showsVerticalScrollIndicator={false}>
                                    <View style={styles.titleBar}>
                                          <TouchableOpacity style={{ marginRight: 6 }} onPress={() => { props.navigation?.goBack() != undefined ? props.navigation?.goBack() : props.navigation.navigate('Home') }}>
                                                <Ionicons name="ios-arrow-back" size={screenSize.height / 30} color='#52575D' />
                                          </TouchableOpacity>

                                          <TouchableOpacity
                                                onPress={() => { setAnimation("slideOutUp"), setOpen(true), setAnimationPressable('fadeIn') }}                                          >
                                                <MaterialCommunityIcons name="dots-vertical" size={24} color="#52575D" />
                                          </TouchableOpacity>
                                    </View>

                                    <View style={{ alignSelf: "center", justifyContent: 'center' }}>
                                          <View style={styles.profileImage}>
                                                <Image resizeMethod='cover' source={{ uri: authenticatedUser?.photoURL }} style={styles.image}></Image>
                                          </View>
                                          <TouchableOpacity onPress={() => { props.navigation.navigate('EditProfile', { userID: authenticatedUser?.uid }) }} style={styles.dm}>
                                                <MaterialIcons name="edit" size={18} color="#DFD8C8"></MaterialIcons>
                                          </TouchableOpacity>
                                    </View>

                                    <View style={styles.infoContainer}>
                                          <Text style={[styles.name, { fontWeight: "200", fontSize: 36 }]}>{authenticatedUser?.displayName}</Text>
                                    </View>
                                    <View style={styles.subinfoContainer}>
                                          <Text style={[styles.subName, { fontWeight: "200", fontSize: 20 }]}>{authenticatedUser?.email}</Text>
                                    </View>
                                    <View style={styles.subinfoContainer}>
                                          <Text style={[styles.subName, { fontWeight: "200", fontSize: 20 }]}>{authenticatedUser?.phoneNumber}</Text>
                                    </View>

                                    <View style={styles.statsContainer}>
                                          <View style={[styles.statsBox]}>
                                                <Text style={[styles.text, styles.subText]}>Ads</Text>
                                                <Text style={[styles.text, { fontSize: 24 }]}>{ads?.length}</Text>
                                          </View>

                                          <View style={{ height: '90%', width: 1, backgroundColor: '#AEB5BC' }} />

                                          <View style={styles.statsBox}>
                                                <Text style={[styles.text, styles.subText]}>Joined</Text>
                                                {/* <Text style={[styles.text, { fontSize: 22 }]}>Time</Text> */}
                                                <Text style={[styles.text, { fontSize: 22 }]}>{displayDate}</Text>
                                                {/* <Text style={[styles.text, { fontSize: 22 }]}>{currentUser?.creationTime.toString().toDate()}</Text> */}
                                                {/* <Text style={[styles.text, { fontSize: 22 }]}>{currentUser?.creationTime}</Text> */}
                                                {/* <Text style={[styles.text, { fontSize: 22 }]}>{currentUser?.creationTime}</Text> */}
                                                {/* <Text style={[styles.text, { fontSize: 22 }]}>{currentUser?.creationTime.toDate().toISOString()}</Text> */}
                                          </View>
                                    </View>

                                    <TextInput
                                          placeholder="Search"
                                          clearButtonMode="always"
                                          style={styles.textInput}
                                          autoCapitalize="none"
                                          value={searchQuery}
                                          onChangeText={(query) => handleSearch(query)} />

                                    <View
                                    // style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} 
                                    >



                                          {/* <div style={{ backgroundColor: 'blueviolet' }}>
                                                <h2>OTP Verification</h2>
                                                <div>
                                                      <label>Phone Number:</label>
                                                      <input
                                                            type="text"
                                                            value={phoneNumber}
                                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                                            placeholder="918144356767"
                                                      />
                                                </div>
                                                <SigninWithPhone phoneNumber={phoneNumber} />
                                          </div> */}



                                          <ScrollView horizontal={false}>
                                                <View style={styles.AdsWraper}>
                                                      {ads.map((item, index) => {
                                                            return (
                                                                  <TouchableOpacity
                                                                        key={index}
                                                                        style={{
                                                                              // width: screenSize.isWide
                                                                              //       ? screenSize.ratio(7)
                                                                              //       : screenSize.ratio(7),
                                                                              // width: screenSize.isWide
                                                                              //       ? screenSize.width / 4.4
                                                                              //       : screenSize.width / 2.1,
                                                                              // marginHorizontal: 2,

                                                                              width: screenSize.isWide
                                                                                    ? screenSize.width / 4.44
                                                                                    : screenSize.width / 2.15,
                                                                              marginHorizontal: 2,
                                                                              marginVertical: 6,
                                                                              // backgroundColor: 'red',
                                                                              // marginTop: 3,
                                                                              // height: '100%',
                                                                              // justifyContent: 'space-between',
                                                                              // alignItems: 'flex-start'
                                                                              // height: '34%',
                                                                              // height: screenSize.isWide ?
                                                                              //       screenSize.width / 3.8
                                                                              //       : screenSize.width / 1.6,
                                                                              // overflow: 'hidden'
                                                                              // flex: 1,
                                                                        }}
                                                                        onPress={() =>
                                                                              props.navigation.navigate("ProductInfo", {
                                                                                    productId: item.id,
                                                                              })
                                                                        }
                                                                  >
                                                                        <Image
                                                                              source={item.productImageList[0].downloadURL}
                                                                              style={{
                                                                                    width: undefined,
                                                                                    height: undefined,
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: 10,
                                                                                    aspectRatio: 1,
                                                                                    resizeMode: "cover",
                                                                              }}
                                                                        ></Image>

                                                                        <View style={{ marginTop: 5 }}>
                                                                              <View>
                                                                                    <Text
                                                                                          numberOfLines={3}
                                                                                          style={{
                                                                                                fontSize: screenSize.ratio(70),
                                                                                                color: "#242424",
                                                                                                fontWeight: "600",
                                                                                          }}
                                                                                    >
                                                                                          {item.ShortDescription}
                                                                                    </Text>
                                                                              </View>

                                                                              <View
                                                                                    style={{
                                                                                          flexDirection: "row",
                                                                                          alignItems: "center",
                                                                                          justifyContent: 'flex-start'
                                                                                          // right: 4,
                                                                                    }}
                                                                              >
                                                                                    <Text style={{
                                                                                          fontSize: screenSize.ratio(55), fontWeight: "bold",
                                                                                          // color: 'green'
                                                                                    }}>
                                                                                          {/* {" "} */}
                                                                                          EGP{" "}
                                                                                    </Text>
                                                                                    <Text style={{
                                                                                          fontSize: screenSize.ratio(55), fontWeight: "bold",
                                                                                          // color: 'green'
                                                                                    }}>
                                                                                          {/* {" "} */}
                                                                                          {" " + item.Price}
                                                                                    </Text>
                                                                              </View>
                                                                        </View>
                                                                  </TouchableOpacity>
                                                                  // <TouchableOpacity
                                                                  //       key={index}
                                                                  //       style={{
                                                                  //             width: screenSize.isWide
                                                                  //                   ? screenSize.width / 4.4
                                                                  //                   : screenSize.width / 2.2,
                                                                  //             marginVertical: 15,
                                                                  //             marginHorizontal: 5
                                                                  //       }}
                                                                  //       onPress={() => props.navigation.navigate("ProductInfo", { productId: item.id })}>

                                                                  //       <View style={{
                                                                  //             width: "100%",
                                                                  //             height: screenSize.isWide
                                                                  //                   ? screenSize.width / 4.5
                                                                  //                   : screenSize.width / 2.2,
                                                                  //             marginBottom: 3,
                                                                  //       }}>
                                                                  //             <Image source={item.photo}
                                                                  //                   style={{
                                                                  //                         width: undefined,
                                                                  //                         height: undefined,
                                                                  //                         alignItems: "center",
                                                                  //                         justifyContent: "center",
                                                                  //                         resizeMode: "cover",
                                                                  //                         borderRadius: 10,
                                                                  //                         aspectRatio: 1,
                                                                  //                   }}>
                                                                  //             </Image>
                                                                  //       </View>
                                                                  //       <Text style={{
                                                                  //             fontSize: 20,
                                                                  //             color: COLOURS.black,
                                                                  //             fontWeight: "600",
                                                                  //       }}>
                                                                  //             {item.ShortDescription}
                                                                  //       </Text>
                                                                  //       <View style={{ flexDirection: "row" }}>
                                                                  //             <Text style={{ fontSize: 18 }}> EGP </Text>
                                                                  //             <Text style={{ fontSize: 18, fontWeight: "bold" }}> {" " + item.Price}</Text>
                                                                  //       </View>
                                                                  // </TouchableOpacity>
                                                            )
                                                      })}
                                                </View>
                                          </ScrollView>
                                    </View>
                              </ScrollView>
                              {/* </ImageBackground> */}
                        </View>
                  </View>
                  //       </SafeAreaProvider>
                  // </GestureHandlerRootView>
            )
      } else if (routeprofileID != undefined && authenticatedUser?.uid != routeprofileID) {
            const date = new Date(routeUser?.creationTime && routeUser?.creationTime.toDate());
            const displayDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + (date.getFullYear());
            return (
                  <View style={{ flex: 1 }}>
                        {isOpen && (
                              <>
                                    <AnimatedPressable animation={animationPressable} duraton="600" style={styles.backdrop} onPress={() => {
                                          setAnimation('slideOutDown')
                                          setAnimationPressable('fadeOut')
                                          setTimeout(toggleSheet, 650)
                                    }} />


                                    <Animatable.View style={styles.sheet} animation={animation} duraton="600" easing='ease-out-back'>
                                          <TouchableOpacity onPress={handleShare}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", marginHorizontal: 20 }}>
                                                      <Text>
                                                            Share Profile
                                                      </Text>
                                                      <MaterialCommunityIcons name="share-variant" size={24} color="#52575D" />
                                                </View>
                                          </TouchableOpacity>
                                    </Animatable.View>
                              </>
                        )}
                        <SafeAreaView style={styles.container}>
                              {/* <ImageBackground
                                    style={styles.backgroundImage}
                                    source={require("../assets/bg.png")}
                                    // source={{ uri: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/37e7be51-44e0-4b80-8339-9f4f80026dfb/d2wh0s0-b74aa555-26e4-4602-9cb2-ad5478571ceb.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzM3ZTdiZTUxLTQ0ZTAtNGI4MC04MzM5LTlmNGY4MDAyNmRmYlwvZDJ3aDBzMC1iNzRhYTU1NS0yNmU0LTQ2MDItOWNiMi1hZDU0Nzg1NzFjZWIucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.S5VQABzZ-z_IWPqYMn68CXZEnsE4f4KEIAhQe7Pq5Fs' }}
                                    blurRadius={200}
                              > */}
                              <ScrollView style={{ height: '100%', width: '100%' }} showsVerticalScrollIndicator={false}>
                                    <View style={styles.titleBar}>
                                          <TouchableOpacity style={{ marginRight: 6 }} onPress={() => props.navigation?.goBack() != undefined ? props.navigation?.goBack() : props.navigation.navigate('HomeScHomereen')}>
                                                <Ionicons name="ios-arrow-back" size={screenSize.height / 30} color='#52575D' />
                                          </TouchableOpacity>

                                          <TouchableOpacity
                                                onPress={() => { setAnimation("slideOutUp"), setOpen(true), setAnimationPressable('fadeIn') }}                                          >
                                                <MaterialCommunityIcons name="dots-vertical" size={24} color="#52575D" />
                                          </TouchableOpacity>
                                    </View>
                                    {/* <View style={{ justifyContent: 'center', alignItems: 'center' }}> */}

                                    <View style={{ alignSelf: "center", justifyContent: 'center' }}>
                                          <View style={styles.profileImage}>
                                                <Image source={{ uri: routeUser?.photoURL }} style={styles.image} resizeMode="cover"></Image>
                                          </View>

                                          <View style={styles.dm}>
                                                <TouchableOpacity onPress={() => props.navigation.navigate("Chat", { productData: routeUser.uid, })}>
                                                      <MaterialIcons name="chat" size={18} color="#DFD8C8"></MaterialIcons>
                                                </TouchableOpacity>
                                          </View>

                                          {/* <View style={styles.active}>
                                          </View> */}
                                    </View>

                                    <View style={styles.infoContainer}>
                                          <Text style={[styles.name, { fontWeight: "200", fontSize: 36 }]}>{routeUser?.displayName}</Text>
                                    </View>
                                    {/* </View> */}

                                    <View style={styles.statsContainer}>
                                          <View style={[styles.statsBox]}>
                                                <Text style={[styles.text, styles.subText]}>Ads</Text>
                                                <Text style={[styles.text, { fontSize: 24 }]}>{ads?.length}</Text>
                                          </View>

                                          <View style={{ height: '90%', width: 1, backgroundColor: '#AEB5BC' }} />

                                          <View style={styles.statsBox}>
                                                <Text style={[styles.text, styles.subText]}>Joined</Text>
                                                <Text style={[styles.text, { fontSize: 22 }]}>{displayDate}</Text>
                                          </View>
                                    </View>


                                    {/* {following ? (
                                          <TouchableOpacity
                                                style={[utils.buttonOutlined, container.container, utils.margin15Right]}
                                                title="Following"
                                                onPress={() => onUnfollow()}>
                                                <Text style={[text.bold, text.center, text.green]}>Following</Text>
                                          </TouchableOpacity>
                                    )
                                          :
                                          (
                                                <TouchableOpacity
                                                      style={[utils.buttonOutlined, container.container, utils.margin15Right]}
                                                      title="Follow"
                                                      onPress={() => onFollow()}>
                                                      <Text style={[text.bold, text.center, { color: '#2196F3' }]}>Follow</Text>
                                                </TouchableOpacity>

                                          )} */}

                                    <Animatable.View
                                          style={{ alignItems: 'center', justifyContent: 'center', marginVertical: 10 }}
                                          ref={rubberBandAnimeRef}>
                                          {/* <LinearGradient
                                                colors={['#24C6DC', '#514A9D']}
                                                style={{}}> */}
                                          {following ?
                                                <TouchableOpacity
                                                      style={[styles.animeButton, { backgroundColor: "#1289A7" }]}
                                                      onPress={() => {
                                                            onUnfollow()
                                                            rubberBandAnimeRef.current.rubberBand(800)
                                                      }}
                                                >
                                                      <Text style={styles.animeButtonText}>
                                                            Following
                                                      </Text>

                                                </TouchableOpacity>
                                                :
                                                <TouchableOpacity
                                                      style={[styles.animeButton, { backgroundColor: "#1289A7" }]}
                                                      onPress={() => {
                                                            onFollow()
                                                            rubberBandAnimeRef.current.rubberBand(800)
                                                      }}
                                                >
                                                      <Text style={styles.animeButtonText}>
                                                            Follow
                                                      </Text>

                                                </TouchableOpacity>}
                                          {/* </LinearGradient> */}
                                    </Animatable.View>

                                    <TextInput
                                          placeholder="Search"
                                          clearButtonMode="always"
                                          style={styles.textInput}
                                          autoCapitalize="none"
                                          value={searchQuery}
                                          onChangeText={(query) => handleSearch(query)}
                                    />

                                    <View
                                    // style={{ flex: 1, }} 
                                    >
                                          <ScrollView horizontal={false}>
                                                <View style={styles.AdsWraper}>

                                                      {ads.map((item, index) => {
                                                            return (
                                                                  <TouchableOpacity
                                                                        key={index}
                                                                        style={{
                                                                              // width: screenSize.isWide
                                                                              //       ? screenSize.ratio(7)
                                                                              //       : screenSize.ratio(7),
                                                                              // width: screenSize.isWide
                                                                              //       ? screenSize.width / 4.4
                                                                              //       : screenSize.width / 2.1,
                                                                              // marginHorizontal: 2,

                                                                              width: screenSize.isWide
                                                                                    ? screenSize.width / 4.44
                                                                                    : screenSize.width / 2.15,
                                                                              marginHorizontal: 2,
                                                                              marginVertical: 6,
                                                                              // backgroundColor: 'red',
                                                                              // marginTop: 3,
                                                                              // height: '100%',
                                                                              // justifyContent: 'space-between',
                                                                              // alignItems: 'flex-start'
                                                                              // height: '34%',
                                                                              // height: screenSize.isWide ?
                                                                              //       screenSize.width / 3.8
                                                                              //       : screenSize.width / 1.6,
                                                                              // overflow: 'hidden'
                                                                              // flex: 1,
                                                                        }}
                                                                        onPress={() =>
                                                                              props.navigation.navigate("ProductInfo", {
                                                                                    productId: item.id,
                                                                              })
                                                                        }
                                                                  >
                                                                        <Image
                                                                              source={item.productImageList[0].downloadURL}
                                                                              style={{
                                                                                    width: undefined,
                                                                                    height: undefined,
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    borderRadius: 10,
                                                                                    aspectRatio: 1,
                                                                                    resizeMode: "cover",
                                                                              }}
                                                                        ></Image>

                                                                        <View style={{ marginTop: 5 }}>
                                                                              <View>
                                                                                    <Text
                                                                                          numberOfLines={3}
                                                                                          style={{
                                                                                                fontSize: screenSize.ratio(70),
                                                                                                color: "#242424",
                                                                                                fontWeight: "600",
                                                                                          }}
                                                                                    >
                                                                                          {item.ShortDescription}
                                                                                    </Text>
                                                                              </View>

                                                                              <View
                                                                                    style={{
                                                                                          flexDirection: "row",
                                                                                          alignItems: "center",
                                                                                          justifyContent: 'flex-start'
                                                                                          // right: 4,
                                                                                    }}
                                                                              >
                                                                                    <Text style={{
                                                                                          fontSize: screenSize.ratio(55), fontWeight: "bold",
                                                                                          // color: 'green'
                                                                                    }}>
                                                                                          {/* {" "} */}
                                                                                          EGP{" "}
                                                                                    </Text>
                                                                                    <Text style={{
                                                                                          fontSize: screenSize.ratio(55), fontWeight: "bold",
                                                                                          // color: 'green'
                                                                                    }}>
                                                                                          {/* {" "} */}
                                                                                          {" " + item.Price}
                                                                                    </Text>
                                                                              </View>
                                                                        </View>
                                                                  </TouchableOpacity>
                                                                  // <TouchableOpacity
                                                                  //       key={index}
                                                                  //       style={{
                                                                  //             width: screenSize.isWide
                                                                  //                   ? screenSize.width / 4.4
                                                                  //                   : screenSize.width / 2.2,
                                                                  //             marginVertical: 15,
                                                                  //             marginHorizontal: 5
                                                                  //       }}
                                                                  //       onPress={() => props.navigation.navigate("ProductInfo", { productId: item.id })}>

                                                                  //       <View style={{
                                                                  //             width: "100%",
                                                                  //             height: screenSize.isWide
                                                                  //                   ? screenSize.width / 4.5
                                                                  //                   : screenSize.width / 2.2,
                                                                  //             marginBottom: 3,
                                                                  //       }}>
                                                                  //             <Image source={item.photo}
                                                                  //                   style={{
                                                                  //                         width: undefined,
                                                                  //                         height: undefined,
                                                                  //                         alignItems: "center",
                                                                  //                         justifyContent: "center",
                                                                  //                         resizeMode: "cover",
                                                                  //                         borderRadius: 10,
                                                                  //                         aspectRatio: 1,
                                                                  //                   }}>
                                                                  //             </Image>
                                                                  //       </View>
                                                                  //       <Text style={{
                                                                  //             fontSize: 20,
                                                                  //             color: COLOURS.black,
                                                                  //             fontWeight: "600",
                                                                  //       }}>
                                                                  //             {item.ShortDescription}
                                                                  //       </Text>
                                                                  //       <View style={{ flexDirection: "row" }}>
                                                                  //             <Text style={{ fontSize: 18 }}> EGP </Text>
                                                                  //             <Text style={{ fontSize: 18, fontWeight: "bold" }}> {" " + item.Price}</Text>
                                                                  //       </View>
                                                                  // </TouchableOpacity>
                                                            )
                                                      })}
                                                </View>
                                          </ScrollView>
                                    </View>
                              </ScrollView>
                              {/* </ImageBackground> */}
                        </SafeAreaView>
                  </View>
            )
      }
}

function useStyles() {
      return StyleSheet.create({
            backgroundImage: {
                  flex: 1,
                  width: '100%',
                  height: '100%',
                  resizeMode: "cover",
            },
            container: {
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  width: ScreenSize().isWide ? '70%' : '100%',
                  alignSelf: 'center'
                  // backgroundColor: "rgba(0, 0, 0, 0.3)"
                  // backgroundColor: "#000"
            },
            text: {
                  fontFamily: "HelveticaNeue",
                  color: "#52575D"
            },
            name: {
                  fontFamily: "HelveticaNeue",
                  color: "#000",
                  // opacity: 0.8
                  // color: "#ffffff"
            },
            subName: {
                  fontFamily: "HelveticaNeue",
                  color: "#000",
                  opacity: 0.8
                  // color: "#ffffff"
            },
            image: {
                  flex: 1,
                  height: undefined,
                  width: undefined
            },
            titleBar: {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 24,
                  marginHorizontal: 16
            },
            subText: {
                  fontSize: 12,
                  color: "#41444B",
                  // color: "#AEB5BC",
                  textTransform: "uppercase",
                  fontWeight: "500"
            },
            profileImage: {
                  width: 180,
                  height: 180,
                  borderRadius: 100,
                  overflow: "hidden",
                  marginTop: -10
            },
            dm: {
                  backgroundColor: "#41444B",
                  position: "absolute",
                  top: 20,
                  width: 35,
                  height: 35,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center"
            },
            active: {
                  backgroundColor: "#34FFB9",
                  position: "absolute",
                  bottom: 28,
                  left: 10,
                  padding: 4,
                  height: 20,
                  width: 20,
                  borderRadius: 10
            },
            add: {
                  backgroundColor: "#41444B",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  width: 60,
                  height: 60,
                  borderRadius: 30,
                  alignItems: "center",
                  justifyContent: "center"
            },
            infoContainer: {
                  justifyContent: "center",
                  // alignSelf: "center",
                  alignItems: "center",
                  marginTop: 16
            },
            subinfoContainer: {
                  justifyContent: "center",
                  // alignSelf: "center",
                  alignItems: "center",
                  marginTop: 5
            },
            statsContainer: {
                  flexDirection: "row",
                  alignSelf: "center",
                  marginTop: 32,

            },
            statsBox: {
                  alignItems: "center",
                  flex: 1,
                  marginHorizontal: 60
            },
            mediaImageContainer: {
                  width: 180,
                  height: 200,
                  borderRadius: 12,
                  overflow: "hidden",
                  marginHorizontal: 10
            },
            mediaCount: {
                  backgroundColor: "#41444B",
                  position: "absolute",
                  top: "50%",
                  marginTop: -50,
                  marginLeft: 30,
                  width: 100,
                  height: 100,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 12,
                  shadowOffset: {
                        width: 5,
                        height: 5
                  },
            },
            recent: {
                  marginLeft: 78,
                  marginTop: 32,
                  marginBottom: 6,
                  fontSize: 10
            },
            recentItem: {
                  flexDirection: "row",
                  alignItems: "flex-start",
                  marginBottom: 16
            },
            activityIndicator: {
                  backgroundColor: "#CABFAB",
                  padding: 4,
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  marginTop: 3,
                  marginRight: 20
            },
            textShortDes: {
                  fontSize: 17,
                  marginLeft: 10,
                  fontWeight: '600'
            },
            textLongDes: {
                  fontSize: 14,
                  marginLeft: 10,
                  color: 'grey'
            },
            SearchImage: {
                  height: 50,
                  width: 50,
                  borderRadius: 10
            },
            itemContainer: {
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 10,
                  marginTop: 10
            },
            textInput: {
                  fontSize: 19,
                  // screenSize.isWide
                  // ? screenSize.width / 50
                  // : screenSize.width / 19,
                  paddingLeft: 10,
                  borderWidth: 2,
                  borderRadius: 8,
                  borderColor: '#5C6372',
                  paddingVertical: 10,
                  marginTop: 6,
                  paddingHorizontal: 20,
                  shadowOffset: 50,
                  shadowOpacity: 30,
                  elevation: 30,
                  marginHorizontal: 10
            },
            AdsWraper: {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  // alignItems: 'center',
                  justifyContent: 'center',
                  // marginTop: 2
                  // width: ScreenSize().isWide ? '70%' : '100%',
                  // alignSelf: 'center'
            },
            animeButtonText: {
                  color: '#fff',
                  fontWeight: '400',
                  fontSize: 22
            },
            animeButton: {
                  backgroundColor: "#5C6372",
                  paddingHorizontal: 80,
                  // screenSize.width / 30,
                  paddingVertical: 3,
                  borderRadius: 4,
                  elevation: 5,
                  shadowColor: '#000',
                  shadowOffset: {
                        width: 2,
                        height: 2
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.5,
            },
            containerr: {
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: ScreenSize().isWide ? '30%' : '100%',
                  alignSelf: 'center',
                  overflow: 'hidden',
                  maxWidth: '100%'
                  // backgroundColor: '#fefeff'
                  // backgroundColor: Colors.mediumGrey
            },
            // container: {
            //       flex: 1,
            //       backgroundColor: '#fff',
            //       alignItems: 'center',
            //       justifyContent: 'center',
            // },
            logoContainer: {
                  position: 'absolute',
                  top: 30,
                  alignItems: 'center',
                  // justifyContent: 'center',

            },
            logOutButton: {
                  paddingHorizontal: 10,
                  marginTop: 30,
                  color: '#00bfff',
                  borderColor: '#00bfff',
                  backgroundColor: '#00bfff',
                  textShadowColor: '#00bfff',
            },
            // logo: {
            //       // alignItems: 'center',
            //       // justifyContent: 'center',
            //       // width: windowWidth -50,
            //       width:
            //             // '400%'
            //             windowWidth * 0.7,
            //       height:
            //             //  500
            //             windowHeight / 2.8
            //       //  - (windowHeight * 2 / 4)
            //       ,
            //       // height: windowHeight - 350,
            //       // resizeMode: 'contain',
            //       borderRadius: 500
            //       // width: '95%',
            //       // height: 420
            // },
            subtitle: {
                  fontSize: 24,
                  fontWeight: '600',
                  paddingVertical: 10,
                  color: '#343434'
            },
            subtitle2: {
                  fontSize: 24,
                  fontWeight: '600',
                  // paddingVertical: 2,
                  color: '#343434'
            },
            buttonContainer: {
                  paddingBottom: 100,
                  width: '90%',
                  // alignItems: 'center',
                  // justifyContent: 'center'
            },
            profilePic: {
                  width: 50,
                  height: 50,
                  // flex: 1
            },
            userInfo: {
                  alignItems: 'center',
                  justifyContent: 'center'
            },
            avatar: {
                  borderRadius: 200,
                  marginBottom: 16,
                  // height: 100,
                  // width: 100,
                  // borderColor: 'white',
                  // borderWidth: StyleSheet.hairlineWidth,
            },
            sheet: {
                  backgroundColor: "white",
                  padding: 16,
                  height: ScreenSize().height / 4,
                  width: "100%",
                  position: "absolute",
                  bottom: -ScreenSize().height / 8,
                  borderRadius: 20,
                  zIndex: 1,
                  // marginHorizontal: 1000,
                  // bottom: -20 * 7,
                  // borderTopRightRadius: 20,
                  // borderTopLeftRadius: 20,
            },
            // backdrop: {
            //       ...StyleSheet.absoluteFillObject,
            //       backgroundColor: "rgba(0, 0, 0, 0.3)",
            //       // zIndex: 1,
            //       // height:
            // },
            panel: {
                  padding: 20,
                  backgroundColor: '#FFFFFF',
                  paddingTop: 20,
                  // borderTopLeftRadius: 20,
                  // borderTopRightRadius: 20,
                  // shadowColor: '#000000',
                  // shadowOffset: {width: 0, height: 0},
                  // shadowRadius: 5,
                  // shadowOpacity: 0.4,
            },
            header: {
                  backgroundColor: '#FFFFFF',
                  shadowColor: '#333333',
                  shadowOffset: { width: -1, height: -3 },
                  shadowRadius: 2,
                  shadowOpacity: 0.4,
                  // elevation: 5,
                  paddingTop: 20,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 20,
            },
            panelHeader: {
                  alignItems: 'center',
            },
            panelHandle: {
                  width: 40,
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: '#00000040',
                  marginBottom: 10,
            },
            panelTitle: {
                  fontSize: 27,
                  height: 35,
            },
            panelSubtitle: {
                  fontSize: 14,
                  color: 'gray',
                  height: 30,
                  marginBottom: 10,
            },
            panelButton: {
                  padding: 13,
                  borderRadius: 10,
                  backgroundColor: '#FF6347',
                  alignItems: 'center',
                  marginVertical: 7,
            },
            panelButtonTitle: {
                  fontSize: 17,
                  fontWeight: 'bold',
                  color: 'white',
            },
            containerrr: {
                  flex: 1,
                  backgroundColor: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
            },
            button: {
                  height: 50,
                  borderRadius: 25,
                  aspectRatio: 1,
                  backgroundColor: 'orange',
                  opacity: 0.6,
            },
            backdrop: {
                  ...StyleSheet.absoluteFillObject,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  zIndex: 1,
            },

      })
}

